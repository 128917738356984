body {
    background : #FCF5F1 !important;
    font-family: 'Inter', sans-serif;
}

div {
    border: 0px solid;
}

.portfolio {
    background: #FCF5F1;
    max-width : 375px;
    overflow  : scroll;



    .card {
        background     : #FEFDFC;
        height         : 88px;
        background     : linear-gradient(0deg, #FEFDFC, #FEFDFC), #FCF5F1;
        box-shadow     : 0px 0px 10px rgba(0, 0, 0, 0.07);
        backdrop-filter: blur(20px);
        /* Note        : backdrop-filter has minimal browser support */
        border-radius  : 8px;
        border         : 0px solid !important;
        padding        : 16px;


        .eth-image {
            height         : 16px;
            width          : 16px;
            background     : url('./eth.png');
            background-size: 16px 16px;
            margin-left    : -2px;

        }

        .estimated {
            font-style    : normal;
            font-weight   : bold;
            font-size     : 11px;
            line-height   : 13px;
            display       : flex;
            align-items   : center;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            color: #63C956;
        }
    }

    .scrollbox {
        overflow: auto;
    }

    ::-webkit-scrollbar {
        width      : 0;
        /* Remove scrollbar space */
        height     : 0;
        background : transparent;
        /* Optional: just make scrollbar invisible */
    }


    .discord {
        width        : 38px;
        height       : 38px;
        border       : 1px solid rgba(0, 0, 0, 0.07);
        box-sizing   : border-box;
        border-radius: 8px;

        .discord-icon {
            background       : url('discord.png');
            background-size  : 30px 17px;
            background-repeat: no-repeat;
            padding          : 16px;
            margin           : 10px 4px 0px 4px;
        }
    }

    h4 {
        margin-top   : 20px;
        color        : #DB4437;
        font-style   : normal;
        font-weight  : 600;
        font-size    : 16px;
        line-height  : 28px;
        text-overflow: ellipsis;

    }

    .nft-item:last-child {
        margin-right: 16px;
    }

    .nft-item:not(:first-child) {
        margin-left: 10px;
    }


    .nft-small-icon {
        width          : 38px;
        height         : 38px;
        border         : 1px solid rgba(0, 0, 0, 0.07);
        border-radius  : 8px;
        background     : #333;
        background-size: 38px 38px !important;
        border         : 0px solid !important;
    }

    .collection-container {
        .collection-row {
            margin-bottom: 12px;
            cursor       : pointer;
            position     : relative;

            .count {
                font-style : normal;
                font-weight: normal;
                font-size  : 16px;
                line-height: 19px;
                display    : flex;
                align-items: center;

                color   : #DB4437;
                position: relative;
            }

            .caret {
                color      : #DB4437;
                font-style : normal;
                font-weight: bold;
                font-size  : 11px;
                padding    : 8px 0px 0px 0px;
                height     : 0px;

                span {
                    font-family: Inter;
                    font-style : normal;
                    font-weight: normal;
                    font-size  : 15px;
                    line-height: 18px;
                    display    : flex;
                    align-items: center;

                    color: #DB4437;
                }
            }
        }

        .name {
            font-style : normal;
            font-weight: normal;
            font-size  : 16px;
            line-height: 26px;
            color      : #2C3137;
        }
    }

    .collection-container.expanded {
        .scrollbox {
            display: flex !important;
        }
    }

    .collection-container.collapsed {

        .scrollbox {
            display: none !important;
        }
    }

    .search-bar {
        height       : 40px;
        background   : rgba(0, 0, 0, 0.05);
        border       : 0.5px solid rgba(0, 0, 0, 0.07);
        box-sizing   : border-box;
        border-radius: 8px;

        input {
            padding-left : 36px;
            border-radius: 8px;
            background   : rgba(0, 0, 0, 0.01);
            border       : 1px none;
            font-size    : 15px;
            font-weight  : 600;
        }

        input::placeholder {
            color: #999;
        }


        .search-icon {
            position         : absolute;
            margin           : 12px;
            height           : 14px;
            width            : 14px;
            background       : url('./search.png');
            background-size  : 14px 14px;
            background-repeat: no-repeat;
        }

        span {
            margin-top : 9px;
            font-weight: 600;
            font-size  : 15px;
            line-height: 18px;

            color: #999999;
        }
    }



    .nft-item {
        cursor: pointer;

        .title {
            font-weight       : 500;
            font-size         : 13px;
            line-height       : 17px;
            color             : #2C3137;
            display           : -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow          : hidden;
        }

        .eth-image {
            height         : 12px;
            width          : 12px;
            background     : url('./eth.png');
            background-size: 12px 12px;
            margin-left    : -2px;
        }



        span {
            font-style : normal;
            font-weight: normal;
            font-size  : 13px;
            display    : flex;

            color: #808387;
        }
    }

    .nft-image {
        height           : 120px;
        width            : 120px;
        border-radius    : 8px;
        background-repeat: no-repeat !important;
        background-size  : cover !important;
    }
}

.eth-value {
    font-style : normal;
    font-weight: 600;
    color      : #2C3137;
    font-size  : 17px;
}

.dollar-value {
    font-size  : 13px;
    color: #808387;
}


body {
    background: #f5f5f5;
}

.loading {
    -webkit-animation-duration       : 1s;
    -webkit-animation-fill-mode      : forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name           : shimmer;
    -webkit-animation-timing-function: linear;
    background                       : rgba(0, 0, 0, 0.07);
    background-image                 : linear-gradient(to right, rgba(255.0, 255.0, 255.0, 0.2) 0%, rgba(255.0, 255.0, 255.0, 0.4) 20%, rgba(255.0, 255.0, 255.0, 0.2) 40%);
    background-repeat                : no-repeat;
    background-size                  : 80px 800px;
    height                           : 800px;
    position                         : relative;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}